import autobind from "autobind-decorator"
import { reactive } from "vue-demi"

@autobind
class Toggler {
  constructor(uniqId, defaultState = false) {
    this.uniqId = uniqId
    this.state = !!defaultState
  }

  enable() {
    this.state = true
  }

  disable() {
    this.state = false
  }

  toggle() {
    this.state ? this.disable() : this.enable()
  }
}

const togglers = new Map()

export const getToggler = uniqId => togglers.get(uniqId)

export const toggleize = (uniqId, defaultState = false) => {
  const toggler = getToggler(uniqId) || reactive(new Toggler(uniqId, defaultState))

  togglers.set(uniqId, toggler)

  return toggler
}
